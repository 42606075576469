const CLOUDS = [
  {
    name: "Amazon",
    value: "aws",
  },
  {
    name: "Azure",
    value: "azure",
  },
  {
    name: "Google cloud",
    value: "google",
  },
  {
    name: "VMware vSphere",
    value: "vmware",
  },
];

export const initialState = {
  clouds: CLOUDS,
  sshs: [],
  credentials: [],
  regions: [],
  vpcids: [],
  selectedLayer: null,
  domainCount: 0,
  nodePools: [
    {
      value: "master",
      label: "Control Plane Node Pool",
    },
    {
      value: "worker",
      label: "Worker Node Pool 1",
    },
  ],
  maxMasterNodes: 5,
  domains: [],
  validating: false,
  selectedCloud: null,
  selectedPolicyMenuAction: "manage-machines",
  isLoadingNameValidation: false,
  estimatedRate: null,
};

export function FORM_LOAD_REQUEST(nextState, { module }, state) {
  if (module === "cluster") {
    Object.keys(initialState).forEach((key) => {
      nextState[key] = initialState[key];
    });
  }

  if (module === "vmware/domain") {
    nextState.domainCount = state.domainCount + 1;
  }
}

export function CLUSTER_CLOUD_SETTINGS_REGIONS_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.regions = result;
}

export function CLUSTER_CLOUD_SETTINGS_SSH_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.sshs = result;
}

export function CLUSTER_CLOUD_SETTINGS_CREDENTIALS_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.credentials = result;
}

export function CLUSTER_CLOUD_SETTINGS_VPCS_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.vpcids = result;
}

// TODO: Please remove, it's already present in the form module cluster/create
export function SET_SELECTED_CLOUD_TYPE(nextState, { selectedCloud }) {
  nextState.selectedCloud = selectedCloud;
}

export function ADD_VSPHERE_DOMAIN(nextState, { data }) {
  nextState.domains.push(data);
}

export function VALIDATE_CLUSTER_REQUEST(nextState) {
  nextState.validating = true;
}

export function VALIDATE_CLUSTER_SUCCESS(nextState) {
  nextState.validating = false;
}

export function VALIDATE_CLUSTER_FAILURE(nextState) {
  nextState.validating = false;
}

export function UPDATE_POLICY_MENU_SELECTION(nextState, { newKey }) {
  nextState.selectedPolicyMenuAction = newKey;
}

export function CLUSTER_NAME_VALIDATION_REQUEST(nextState) {
  nextState.isLoadingNameValidation = true;
}

export function CLUSTER_NAME_VALIDATION_SUCCESS(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function CLUSTER_NAME_VALIDATION_FAILURE(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function FETCH_CREATE_CLUSTER_RATE_SUCCESS(nextState, { result }) {
  nextState.estimatedRate = result;
}

export function CLUSTER_CREATE_RESET_ESTIMATED_RATE(nextState) {
  nextState.estimatedRate = null;
}
