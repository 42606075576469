import React, { createContext, useRef } from "react";
import styled from "styled-components";
export const PopupContainerContext = createContext({});

const PopupContainer = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
`;

export function PopupContainerProvider({ children, enabled }) {
  const containerElement = useRef();
  if (!enabled) {
    return (
      <PopupContainer className="popup-container" ref={containerElement}>
        {children}
      </PopupContainer>
    );
  }

  return (
    <PopupContainer className="popup-container" ref={containerElement}>
      <PopupContainerContext.Provider value={containerElement}>
        {children}
      </PopupContainerContext.Provider>
    </PopupContainer>
  );
}
