import i18n from "i18next";
import { createSelector } from "reselect";

import { getEntity } from "utils/entities";
import { DEFAULT_SCHEDULE_OPTIONS } from "utils/constants";
import { BackupSchema } from "utils/schemas";
import { getCronScheduleValue } from "utils/parsers";

import {
  backupStatusesFetcher,
  workspaceBackupDetailsModal,
} from "../services/backups";

export const getWorkspaceBackupSchedule = createSelector(
  (state) => backupStatusesFetcher.selector(state),
  ({ result }) => {
    const scheduledRunTime = result?.spec?.config?.schedule?.scheduledRunTime;
    const defaultScheduleLabel = DEFAULT_SCHEDULE_OPTIONS.find(
      (option) => option.value === scheduledRunTime
    )?.label;

    if (!scheduledRunTime) {
      return () => i18n.t("Never");
    }
    if (!defaultScheduleLabel) {
      return () => i18n.t("Custom");
    }
    return defaultScheduleLabel;
  }
);

export const getWorkspaceBackupDetails = getEntity(
  () => workspaceBackupDetailsModal?.data?.guid,
  BackupSchema
);

export const getScheduledBackupPayload = (data) => {
  if (!data || !data.backupPrefix) {
    return {};
  }

  const {
    backupPrefix,
    location,
    schedule,
    occurrence,
    expiryPeriod,
    expiryHours,
    includeAllClusters,
    includeAllDisks,
    includeClusterResourceMode,
    backupedNamespaces,
    backupedClusters,
  } = data;

  return {
    backupConfig: {
      backupLocationUid: location,
      backupPrefix,
      durationInHours: expiryPeriod === "custom" ? expiryHours : expiryPeriod,
      schedule: {
        scheduledRunTime: getCronScheduleValue(schedule, occurrence),
      },
      includeAllDisks,
      includeClusterResourceMode,
      namespaces: backupedNamespaces,
    },
    clusterUids: backupedClusters,
    includeAllClusters,
  };
};
