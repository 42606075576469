import store from "services/store";
import { clusterListActions } from "state/cluster/actions/list/clusters";
import { getCurrentProjectID } from "state/auth/selectors/common";

export function onRepaveStatusRedirect(repaveStatus) {
  const currentProjectId = getCurrentProjectID(store.getState());
  const module = `clusters/${currentProjectId}`;

  store.dispatch(
    clusterListActions.changeQuery({
      name: "repaveState",
      value: [repaveStatus],
      module,
    })
  );
}
