import i18n from "i18next";
import { createSelector } from "reselect";

import { getEntity } from "utils/entities";
import { DEFAULT_SCHEDULE_OPTIONS } from "utils/constants";
import { BackupSchema } from "utils/schemas";
import { getCronScheduleValue } from "utils/parsers";

import {
  backupStatusesFetcher,
  workspaceBackupDetailsModal,
} from "../services/backups";
import { getClusterGroupEntity } from "./details";

export const getWorkspaceBackupSchedule = createSelector(
  (state) => backupStatusesFetcher.selector(state),
  ({ result }) => {
    const scheduledRunTime = result?.spec?.config?.schedule?.scheduledRunTime;
    const defaultScheduleLabel = DEFAULT_SCHEDULE_OPTIONS.find(
      (option) => option.value === scheduledRunTime
    )?.label;

    if (!scheduledRunTime) {
      return () => i18n.t("Never");
    }
    if (!defaultScheduleLabel) {
      return () => i18n.t("Custom");
    }
    return defaultScheduleLabel;
  }
);

export const getWorkspaceBackupDetails = getEntity(
  () => workspaceBackupDetailsModal?.data?.guid,
  BackupSchema
);

export const getScheduledBackupPayload = (data) => {
  if (!data || !data.backupName) {
    return {};
  }

  const {
    backupName,
    location,
    schedule,
    occurrence,
    expiryPeriod,
    expiryHours,
    includeAllClusters,
    includeAllDisks,
    includeClusterResourceMode,
    backupedClusters,
  } = data;

  return {
    backupConfig: {
      backupLocationUid: location,
      backupName,
      durationInHours: expiryPeriod === "custom" ? expiryHours : expiryPeriod,
      schedule: {
        scheduledRunTime: getCronScheduleValue(schedule, occurrence),
      },
      includeAllDisks,
      includeClusterResourceMode,
    },
    clusterUids: backupedClusters,
    includeAllClusters,
  };
};

export const getWorkspaceClusters = createSelector(
  getClusterGroupEntity,
  (workspace) => {
    // TODO: update selector
    return workspace?.spec?.clusterRefs || [];
  }
);
